import React from 'react';
import { LINK_TYPE } from '@/types';
import formatPhoneNumber from '@/utils/formatPhoneNumber';
import { AppBar, Box } from '@mui/material';
import { shape, string } from 'prop-types';
import Link from '../Link';

const Header = ({ header: { logo, midiLogo, phoneNumber, link, thdLogo } }) => (
  <AppBar position="sticky" color="inherit" elevation={5} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
    <Box
      height={66}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      maxWidth={1440}
      width="100%"
      margin="0 auto"
      mt={1}
      px={{ xs: 2, md: 5 }}
    >
      {/* Bellhop Logo */}
      <Box>
        <Link display={{ xs: 'none', sm: 'block' }} href={logo.href}>
          <img width={150} src={logo.url} alt={logo.alt} />
        </Link>
        <Link display={{ xs: 'block', sm: 'none' }} href={logo.href}>
          <img width={50} src={midiLogo.url} alt={midiLogo.alt} />
        </Link>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Box mr={1}>
          {/* Secondary CTA } */}
          {link && (
            <Link
              display="flex"
              alignItems="center"
              justifyContent="end"
              px={2}
              sx={{
                '&:hover': {
                  color: 'text.primary',
                  backgroundColor: 'neutral.lighter',
                },
              }}
              href={link.href}
              variant="body2"
              color="primary.main"
              fontFamily="h1.fontFamily"
            >
              {link.label}
            </Link>
          )}

          {/* General Navigation */}
          <Link
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={2}
            sx={{
              '&:hover': {
                'font-weigth': 700,
                color: 'primary.main',
                backgroundColor: 'neutral.lighter',
              },
            }}
            href={`tel:${phoneNumber}`}
            variant="body2"
            color="text.primary"
          >
            {formatPhoneNumber(phoneNumber)}
          </Link>
        </Box>

        {/* THD Logo */}
        <Box mt={1}>
          <Link href={thdLogo?.href}>
            <img width={56} src={thdLogo?.url} alt={thdLogo?.alt} />
          </Link>
        </Box>
      </Box>
    </Box>
  </AppBar>
);

Header.propTypes = {
  header: shape({
    logo: shape({
      alt: string,
      url: string,
    }),
    midiLogo: shape({
      alt: string,
      url: string,
    }),
    thdLogo: shape({
      alt: string,
      url: string,
    }),
    link: LINK_TYPE,
    phoneNumber: string,
  }).isRequired,
};

export default Header;
